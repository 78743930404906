<template>
  <div>
    <b-card>
      <div class="app_title_box">
        <h4>County List</h4>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="showModal"
        >
          New
        </b-button>
      </div>
      <div class="mb-2">
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Filter By State"
              label-for="v-category"
            >
              <b-form-select
                id="h-category"
                v-model="active_state"
                :options="state_options"
                @change="filterState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Search by keyword"
              label-for="v-search-keyword"
            >
              <b-form-input
                id="h-search-keyword"
                v-model="filter"
                type="text"
                placeholder="Search by keyword..."
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-table
        responsive="sm"
        :items="counties"
        :fields="fields"
        :per-page="perPage"
        sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        :current-page="currentPage"
        @row-clicked="showDetails"
      >
        <template #cell(state_id)="data">
          {{ states.filter(item => item.id === data.value)[0].name }}
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="lg"
        class="my-0"
      />
    </b-card>

    <b-modal
      id="app-modal"
      :title="is_update ? 'Uptate County' : 'Add New County'"
      hide-footer
      @hide="hide"
    >
      <b-card-text>
        <validation-observer ref="countyForm">
          <b-form @submit.prevent="is_update ? updateCounty() : createCounty()">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="State"
                  label-for="v-state"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="state"
                    rules="required"
                  >
                    <b-form-select
                      id="h-state"
                      v-model="county.state_id"
                      :state="errors.length > 0 ? false:null"
                      :options="state_options"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- first name -->
              <b-col cols="12">
                <b-form-group
                  label="County Name"
                  label-for="v-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="v-name"
                      v-model="county.name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>
            <div class="mt-4 d-flex align-items-center justify-content-between">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>

              <b-button
                v-if="is_update"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                @click="deleteData"
              >
                Delete
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                type="submit"
                :disabled="is_edited === false && is_update ? true : false"
              >
                Save
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BTable, BRow, BCol, BPagination, BFormGroup, BFormInput, BForm, BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BPagination,
    BButton,
    BModal,
    BCardText,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      is_update: false,
      required,
      selected: 1,
      perPage: 10,
      pageOptions: [3, 5, 10],
      currentPage: 1,
      filter: null,
      sortBy: 'id',
      sortDesc: false,
      filterOn: [],
      fields: [{ key: 'id', label: '#ID' }, { key: 'state_id', label: 'State' }, { key: 'name', label: 'County Name', sortable: true }],
      county: {
        state_id: 1,
        name: '',
      },
      active_state: 1,
      is_edited: false,
    }
  },
  computed: {
    states() {
      return this.$store.state.state.states
    },
    counties() {
      return this.$store.state.county.counties
    },
    state_options() {
      return this.states.map(item => {
        const data = { value: item.id, text: `${item.name}` }
        return data
      })
    },
    totalRows() {
      return this.counties.length
    },
  },
  watch: {
    '$store.state.county.all_counties': {
      handler() {
        this.getCounty()
      },
      deep: true,
    },
    county: {
      handler(oldValue, newValue) {
        if (newValue && this.is_update) {
          this.is_edited = true
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getCounty()
  },
  methods: {
    getState() {
      return this.$store.dispatch('state/getStates')
    },
    getCounty() {
      return this.$store.dispatch('county/getCounties', this.active_state)
    },
    filterState() {
      return this.$store.dispatch('county/getCounties', this.active_state)
    },
    createCounty() {
      this.$refs.countyForm.validate().then(success => {
        if (success) {
          this.$store.dispatch('county/createCounty', this.county)
          this.hide()
        }
      })
    },
    updateCounty() {
      this.$refs.countyForm.validate().then(success => {
        if (success) {
          this.$store.dispatch('county/updateCounty', this.county)
          this.is_edited = false
          this.hide()
        }
      })
    },
    deleteData() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('county/deleteCounty', this.county)
          this.is_edited = false
          this.hide()
        }
      })
    },
    emptyData() {
      const dom = this
      setTimeout(() => {
        dom.county = {
          state_id: 1,
          name: '',
        }
      }, 200)
    },
    showModal() {
      this.$bvModal.show('app-modal')
    },
    hide(e) {
      if (this.is_edited === true) {
        e.preventDefault()
        this.$swal({
          title: 'Do you want to save changes',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save Changes',
          cancelButtonText: 'Discard Changes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.updateCounty()
            this.is_edited = false
          } else if (result.dismiss === 'cancel') {
            this.is_update = false
            this.is_edited = false
            this.$bvModal.hide('app-modal')
            this.emptyData()
          }
        })
      } else {
        this.is_update = false
        this.$bvModal.hide('app-modal')
        this.is_edited = false
        this.emptyData()
      }
    },
    showDetails(item) {
      this.is_update = true
      this.county = item
      this.$bvModal.show('app-modal')
      const dom = this
      setTimeout(() => {
        dom.is_edited = false
      }, 1000)
    },
  },
}
</script>

  <style>

  </style>
